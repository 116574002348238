body {
	font-family: 'Ford Antenna Regular', serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin: 0;
}

.ford-bg-primary {
	background-color: var(--primary-ui);
}
.ford-primary {
	color: var(--primary-ui);
}

span.loader-img {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 100px;
}
.loader {
	position: fixed;
	top: 0px;
	right: 0;
	bottom: 0;
	left: 0px;
	z-index: 1070;
	background-color: var(--background-color);
	opacity: 0.5;
}

@media (max-width:320px) {
	.loader {
		top: 0 !important;
		left: 0 !important;
	}

	.layout-footer {
		left: 35px;
	}
}

@media (max-width:481px) {
	.loader {
		top: 0 !important;
		left: 0 !important;
	}

	.layout-footer {
		left: 35px;
	}
}

@media (max-width:641px) {
	.loader {
		top: 0 !important;
		left: 0 !important;
	}

	.layout-footer {
		left: 35%;
	}
}

.datatable-small .p-paginator .p-paginator-first,
.datatable-small .p-paginator .p-paginator-prev,
.datatable-small .p-paginator .p-paginator-next,
.datatable-small .p-paginator .p-paginator-last,
.datatable-small .p-paginator .p-paginator-pages .p-paginator-page
{
  min-width: 2rem !important;
  font-size: 0.8rem !important;
}

.react-datepicker-popper {
	top: -18px !important;
}

.react-datepicker-wrapper {
	margin-bottom: -18px !important;
}

.icon:hover path {
    fill: var( --background);
}

.bg-transparent {
	background: transparent !important;
}

.p-button:disabled {
	background-color: rgba(0, 0, 0, 0) !important; 
    color: rgba(0, 0, 0, 0.38) !important;
    opacity: 0.1;
}

.datatable-bg th {
    background: lightgray;
}